import { useContext, useState,useEffect } from 'react';
import styled from 'styled-components';
import { ScreensContext } from '../../context/screensContex';

import { Pill } from '../uiElements';
import { pillsTypes } from '../uiElements/pills';
import { v4 as uuidv4 } from 'uuid';
import { getFlowsCategoryFilter } from '../../supabase';

const CategoryTabBar =  () => {
	const { setFilterItem } = useContext(ScreensContext);
	const [d, setd] = useState([
		{ category: 'All', categoryStatus: '', status: 'active', id: 1 },
	]);
	
	useEffect(() => {
		async function getCategory() {
			const result = await getFlowsCategoryFilter();
	
			// Only append the fetched categories, not the initial one again
			const updatedData = result.map((item, i) => ({
				category: item,
				categoryStatus: item,
				status: '',
				id: i + 2, // Ensure the id starts from 2
			}));
	
			// Set the state by appending only the new data
			setd((prevData) => prevData.length === 1 ? [...prevData, ...updatedData] : prevData);
		}
	
		getCategory();
	}, []);
	
	

	const onClickPill = (e,result) => {
		const noActiveD = d.map((x) => ({
			...x,
			status:""
		}))
		const setActive = noActiveD.map((x) => {
			if (x.id === result.id) {
				x.status ="active"
				return x
			} 
			return x
		})
		setd(setActive)
		setFilterItem(result.categoryStatus);
	};

	return (
		<CategoryTabContainer>
			<CategoryTabWrapper>
				{
					<>
			
						{d.map((result) => {
							return (
								<Pill key={uuidv4()} type={pillsTypes.category}>
									<button
										className={`pills ${result.status}`}
										onClick={(e) => onClickPill(e, result)}
										name={result.categoryStatus}
									>
										{result.category}
									</button>
								</Pill>
							);
						})}
					</>
				}
			</CategoryTabWrapper>
		</CategoryTabContainer>
	);
};

const CategoryTabContainer = styled.section`
	margin: 1.5em 0;
	padding: 1em 0;
	border: 1px solid var(--light-grey-color);
	position: sticky;
	top: 80px;
	background: white;
	z-index: 98;
	@media (min-width: 768px) {
		margin: 3em 0;
	}
`;

const CategoryTabWrapper = styled.div`
	display: flex;
	flex-wrap: nowrap;
	overflow-x: scroll;
	gap: 0.8em;

	::-webkit-scrollbar {
		display: none;
	}
`;

export default CategoryTabBar;
